import { createAction } from '@reduxjs/toolkit';

export const socketConnect = createAction('socket_connect');
export const socketDisconnect = createAction('socket_disconnect');

// CONTEXT
export interface SocketGetContextParams {
  refresh: boolean,
}
export const socketGetContext = createAction<SocketGetContextParams>('socket_get_context');

// STATS
export const socketSubscribeStats = createAction('socket_subscribe_stats');
export const socketUnsubscribeStats = createAction('socket_unsubscribe_stats');
export const socketGetStats = createAction('socket_get_stats');

// SESSIONS
export enum LiveSessionStatus {
  INITIALIZED = 'initialized',
  RUNNING = 'running',
  TERMINATED = 'terminated',
  CANCEL = 'cancel',
}
export interface SocketGetSessionParams {
  vhost?: string,
  app?: string,
  stream?: string,
  search?: string,
  limit?: number,
  skip?: number,
  sortField?: string,
  sort?: 1 | -1,
  status?: LiveSessionStatus | LiveSessionStatus[],
  onlyWithRecords?: boolean,
  onlyWithRedirections?: boolean,
}
export const socketGetLiveSessions = createAction<SocketGetSessionParams>('socket_get_live_sessions');
export const socketGetRecordSessions = createAction<SocketGetSessionParams>('socket_get_record_sessions');

export interface SocketStopStreamParams {
  liveSession: string,
}
export const socketStopStream = createAction<SocketStopStreamParams>('socket_stop_stream');

export interface SocketStartRecordParams {
  liveSession: string,
  tracks?: string[],
}
export const socketStartRecord = createAction<SocketStartRecordParams>('socket_start_record');

export interface SocketStopRecordParams {
  recordSession: string,
}
export const socketStopRecord = createAction<SocketStopRecordParams>('socket_stop_record');

export interface SocketAddRedirectionParams {
  liveSession: string,
  url: string,
  key?: string,
  tracks?: string[],
}
export const socketAddRedirection = createAction<SocketAddRedirectionParams>('socket_add_redirection');

export interface SocketUploadRecordParams {
  uploadProfile: string,
  recordSession: string,
}
export const socketUploadRecord = createAction<SocketUploadRecordParams>('socket_upload_record');

export interface SocketDeleteRedirectionParams {
  redirectionSession: string,
}
export const socketDeleteRedirection = createAction<SocketDeleteRedirectionParams>('socket_delete_redirection');


// LOGS
export const socketSubscribeLogs = createAction('socket_subscribe_logs');
export const socketUnsubscribeLogs = createAction('socket_unsubscribe_logs');
export const socketGetLogs = createAction('socket_get_logs');

export default {
  socketConnect,
  socketDisconnect,
  socketGetContext,
  socketSubscribeStats,
  socketUnsubscribeStats,
  socketGetStats,
  socketStopStream,
  socketStartRecord,
  socketStopRecord,
  socketAddRedirection,
  socketUploadRecord,
  socketDeleteRedirection,
  socketGetLiveSessions,
  socketGetRecordSessions,
  socketSubscribeLogs,
  socketUnsubscribeLogs,
  socketGetLogs,
};
