import { getDateDifferenceText, OverlayHoverMessage, PopupButtonType, PopupIconType, showPopup, Touchable } from "@kalyzee/kast-app-web-components";
import React, { createRef, useImperativeHandle, useRef } from "react";
import { ReactComponent as IconClose } from "../../assets/icons/close.svg";
import { ReactComponent as IconTracks } from "../../assets/icons/tracks.svg";
import { ReactComponent as IconUrl } from "../../assets/icons/url.svg";
import Colors from "../../constants/colors";
import { useRender } from "../../hooks/component";
import {
  OvenMediaRedirectionSession,
  OvenMediaRedirectionSessionStatus,
  OvenMediaStreamContext,
  OvenMediaTrackAudio,
  OvenMediaTrackVideo
} from "../../interfaces/context";
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableStyle } from "../utils/Table";
import TableAudioTrack from "./table-audio-track";
import styles from "./table-redirection.module.css";
import TableVideoTrack from "./table-video-track";

export interface TableRedirectionData {
  context: OvenMediaStreamContext;
  session: OvenMediaRedirectionSession;
}

export interface TableRedirectionRef {
  render: () => void;
}

export interface TableRedirectionProps {
  data: TableRedirectionData[];
  onStopRedirection?: (item: TableRedirectionData) => void;
  onItemChecked?: (item: TableRedirectionData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableRedirection = React.forwardRef(
  ({ data, onStopRedirection, onItemChecked, className, style }: TableRedirectionProps, forwardRef: React.ForwardedRef<TableRedirectionRef | undefined>) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const render = useRender();

    useImperativeHandle(forwardRef, () => ({
      render,
    }));

    const generateConfiguration = () => {
      const columnConfiguration: TableConfColumn<TableRedirectionData>[] = [
        {
          type: TableColumnType.CLASSIC,
          key: "startedAt",
          minWidth: "13rem",
          title: "Date" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
        },
        {
          type: TableColumnType.CLASSIC,
          key: "duration",
          width: "7rem",
          title: "Duration" /* TRANSLATION */,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "status",
          title: "Status" /* TRANSLATION */,
          width: "8rem",
        },
        {
          type: TableColumnType.CLASSIC,
          key: "tracks",
          title: "Tracks" /* TRANSLATION */,
          width: "3rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "url",
          title: "Url" /* TRANSLATION */,
          width: "3rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "actions",
          title: "Actions" /* TRANSLATION */,
          width: "5rem",
          enableSort: false,
        },
      ];

      const tableConfiguration: TableConf<TableRedirectionData> = {
        columns: columnConfiguration,
        header: {
          className: styles.tableHeader,
          cell: {
            className: styles.tableHeaderCell,
          },
        },
        row: {
          className: styles.tableRow,
          cell: {
            className: styles.tableRowCell,
          },
        },
        content: { className: styles.tableContent },
        valueToShowIfUndefined: { value: "-", className: styles.tableUndefinedValue },
      };

      return tableConfiguration;
    };

    // Called when a value is changed. Checkboxes here
    const valueChanged = (value: any, columnKey: string, item: TableRedirectionData) => {
      if (columnKey === "checked") onItemChecked?.(item);
    };

    const customRenderCell = (element: JSX.Element | null, elementRef: TableContentRef, columnKey: string, item: TableRedirectionData) => {
      if (columnKey === "url") {
        const iconSize = 20;
        const id = item.session.id;
        const url = item.session.redirection;
        const key = item.session.redirectionKey;

        if (!id) return null;
        return (
          <div className={styles.actions}>
            <Touchable
              onPress={() => {
                showPopup({
                  content: (
                    <div>
                      <div className={styles.popupTitle}>{"Redirection:" /* TRANSLATION */}</div>
                      <div className={styles.popupSubtitle}>Url:</div>
                      <div className={styles.popupValue}>
                        <a href={url} target="_blank">{`${url}`}</a>
                      </div>
                      { key ? (
                        <>
                          <div className={styles.popupSubtitle}>Key:</div>
                          <div className={styles.popupValue}>
                            {key}
                          </div>
                        </>
                      ) : null}
                    </div>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              <IconUrl width={iconSize} height={iconSize} />
            </Touchable>
          </div>
        );
      }
      if (columnKey === "actions") {
        const iconSize = 30;
        const id = item.session.id;
        const status = item.session.status;
        if (!id) return null;
        return (
          <div className={styles.actions}>
            {status === OvenMediaRedirectionSessionStatus.RUNNING ? (
              <Touchable
                onPress={() => {
                  showPopup({
                    title: "Arrêter la redirection",
                    iconTitle: PopupIconType.WARNING,
                    content: `Êtes-vous sûr de vouloir arrêter la redirection ?`,
                    buttons: [
                      { type: PopupButtonType.CANCEL, element: "Non" },
                      {
                        type: PopupButtonType.VALIDATE,
                        element: "Oui",
                        onClick: async () => {
                          onStopRedirection?.(item);
                          return true;
                        },
                      },
                    ],
                    enableBackdropDismiss: true,
                    enableCloseButton: true,
                  });
                }}
              >
                <IconClose stroke={Colors.getTorchRed()} strokeWidth={2} width={iconSize} height={iconSize} />
              </Touchable>
            ) : null}
          </div>
        );
      }
      if (columnKey === "tracks") {
        const tracks = item.session.tracks;
        const videoTracks: OvenMediaTrackVideo[] = [];
        const audioTracks: OvenMediaTrackAudio[] = [];

        if (tracks && item.session.tracks && item.context.outputs) {
          item.context.outputs.forEach((o) => {
            if (o.tracks) {
              o.tracks.forEach((track) => {
                if (tracks.includes(track.name)) {
                  if (track.type === "Video") {
                    videoTracks.push(track);
                  } else if (track.type === "Audio") {
                    audioTracks.push(track);
                  }
                }
              });
            }
          });
        }

        return (
          <Touchable
            className={styles.tracks}
            onPress={() => {
              showPopup({
                content: (
                  <div style={{ minWidth: 300 }}>
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Tracks:" /* TRANSLATION */}</div>
                    {
                      <div className={styles.tracksContainer}>
                        <div>{JSON.stringify(tracks)}</div>
                        {videoTracks.length ? (
                          <>
                            <div style={{ marginTop: "10px" }} />
                            <div className={styles.tracksSeparator}>
                              <div className={styles.tracksSeparatorLine} />
                              <div className={styles.tracksSeparatorTitle}>{"Video" /* TRANSLATION */}</div>
                              <div className={styles.tracksSeparatorLine} />
                            </div>
                            <TableVideoTrack data={videoTracks} />
                          </>
                        ) : null}
                        {audioTracks.length ? (
                          <>
                            <div className={styles.tracksSeparator} style={{ marginTop: "10px" }}>
                              <div className={styles.tracksSeparatorLine} />
                              <div className={styles.tracksSeparatorTitle}>{"Audio" /* TRANSLATION */}</div>
                              <div className={styles.tracksSeparatorLine} />
                            </div>
                            <TableAudioTrack data={audioTracks} />
                          </>
                        ) : null}
                      </div>
                    }
                    <br />
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            <IconTracks width={30} height={30} />
          </Touchable>
        );
      }
      if (columnKey == "tracks") {
        if (!item.session.tracks) return undefined;
        const ref = createRef<HTMLDivElement>();
        return (
          <div ref={ref}>
            {`[${item.session.tracks?.join(", ")}]`}
            <OverlayHoverMessage targetRef={ref} message={`Tracks: [${item.session.tracks?.join(", ")}]`} />
          </div>
        );
      }
      return element;
    };

    const addCustomStyleOnCell = (columnKey: string, item: TableRedirectionData) => {
      const result: TableStyle = {};
      return result;
    };

    const addCustomStyleOnRow = (item: TableRedirectionData, currData: TableRedirectionData[], index: number) => {
      const result: TableStyle = {};
      return result;
    };

    const onRenderTableStarts = () => {};

    const onRenderTableEnded = () => {};

    const transformValue = (columnKey: string, item: TableRedirectionData, initialValue: any, data: TableRedirectionData[], index: number) => {
      if (columnKey == "startedAt") {
        if (!item.session?.startedAt) return undefined;
        const startedAt = new Date(item.session?.startedAt);
        return `${startedAt.toLocaleDateString()} ${startedAt.toLocaleTimeString()}`;
      }
      if (columnKey === "duration") {
        if (item.session?.startedAt && !item.session?.endedAt) return "en cours...";
        else if (!item.session?.startedAt || !item.session?.endedAt) return undefined;

        const startedAt = new Date(item.session.startedAt);
        const endedAt = new Date(item.session.endedAt);
        return getDateDifferenceText(startedAt, endedAt);
      }
      if (columnKey == "tracks") {
        return item.session.tracks ? "" : undefined;
      }
      if (columnKey == "status") {
        return item.session.status;
      }
    };

    const renderTable = () => (
      <Table
        className={styles.table}
        data={data}
        keyExtractor={(_, item) => `key-${item.session.id}`}
        configuration={generateConfiguration()}
        onRenderCellRow={customRenderCell}
        onStyleCellRow={addCustomStyleOnCell}
        onStyleRow={addCustomStyleOnRow}
        onChangeValue={valueChanged}
        onRenderStarts={() => onRenderTableStarts}
        onRenderEnded={onRenderTableEnded}
        transformValue={transformValue}
      />
    );

    const classes = [styles.container];
    if (className) classes.push(className);
    return (
      <div className={classes.join(" ")} style={style} ref={containerRef}>
        {renderTable()}
      </div>
    );
  }
);

TableRedirection.defaultProps = {
  className: undefined,
  style: undefined,
};

export default TableRedirection;
