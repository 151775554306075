import { OverlayHoverMessage, PopupButtonType, showPopup, TableSortDirection, Touchable } from "@kalyzee/kast-app-web-components";
import React, { createRef, useImperativeHandle, useRef } from "react";
import Colors from "../../constants/colors";
import { useRender } from "../../hooks/component";
import { OvenMediaUploadProfile, OvenMediaUploadProfileState } from "../../interfaces/context";
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableStyle } from "../utils/Table";
import styles from "./table-upload-profiles.module.css";


export interface TableUploadProfileData extends OvenMediaUploadProfile {}

export interface TableUploadProfileRef {
  render: () => void;
}

export interface TableUploadProfileProps {
  data: TableUploadProfileData[];
  sort?: { field: string; direction: -1 | 1 };
  onSort?: (field: string, direction: -1 | 1) => void;
  onItemChecked?: (item: TableUploadProfileData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableUploadProfile = React.forwardRef(
  ({ sort, onSort, data, onItemChecked, className, style }: TableUploadProfileProps, forwardRef: React.ForwardedRef<TableUploadProfileRef | undefined>) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const render = useRender();

    useImperativeHandle(forwardRef, () => ({
      render,
    }));

    const generateConfiguration = () => {
      const columnConfiguration: TableConfColumn<TableUploadProfileData>[] = [
        {
          type: TableColumnType.CLASSIC,
          key: "name",
          minWidth: "15rem",
          title: "Name" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "state",
          width: "7rem",
          title: "State" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "protocole",
          width: "7rem",
          title: "Protocole" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "type",
          width: "7rem",
          title: "Type" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "host",
          width: "20rem",
          title: "Host" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "protocole",
          width: "7rem",
          title: "Protocole" /* TRANSLATION */,
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "path",
          title: "Remote path" /* TRANSLATION */,
          width: "25rem",
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "username",
          title: "Username" /* TRANSLATION */,
          width: "10rem",
          enableSort: true,
        },
      ];

      const defaultSort = sort ?? { field: "startedAt", direction: -1 };
      columnConfiguration.forEach((c) => {
        if (c.key === defaultSort.field) {
          c.defaultSort = defaultSort.direction === 1 ? TableSortDirection.ASC : TableSortDirection.DESC;
        }
      });

      const tableConfiguration: TableConf<TableUploadProfileData> = {
        columns: columnConfiguration,
        header: {
          className: styles.tableHeader,
          cell: {
            className: styles.tableHeaderCell,
          },
        },
        row: {
          className: styles.tableRow,
          cell: {
            className: styles.tableRowCell,
          },
        },
        content: { className: styles.tableContent },
        valueToShowIfUndefined: { value: "-", className: styles.tableUndefinedValue },
      };

      return tableConfiguration;
    };

    // Called when a value is changed. Checkboxes here
    const valueChanged = (value: any, columnKey: string, item: TableUploadProfileData) => {
      if (columnKey === "checked") onItemChecked?.(item);
    };

    const customRenderCell = (element: JSX.Element | null, elementRef: TableContentRef, columnKey: string, item: TableUploadProfileData) => {
      if (columnKey == "state") {
        const color = item.state === OvenMediaUploadProfileState.DISABLED ? Colors.getTorchRed() : Colors.getMainGreen();
        return <div style={{ color }}>{item.state}</div>;
      }
      if (columnKey === "name") {
        const ref = createRef<HTMLDivElement>();
        const name = item.name ?? '-';
        return (
          <div ref={ref} className={styles.name}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Name:" /* TRANSLATION */}</div>
                      <div>{name}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {name}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={name} />
          </div>
        );
      }
      if (columnKey === "host") {
        const ref = createRef<HTMLDivElement>();
        const host = item.host ?? '-';
        return (
          <div ref={ref} className={styles.host}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Host:" /* TRANSLATION */}</div>
                      <div>{host}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {host}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={host} />
          </div>
        );
      }
      if (columnKey === "path") {
        const ref = createRef<HTMLDivElement>();
        const path = item.path ?? '-';
        return (
          <div ref={ref} className={styles.path}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Remote path:" /* TRANSLATION */}</div>
                      <div>{path}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {path}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={path} />
          </div>
        );
      }
      return element;
    };

    const addCustomStyleOnCell = (columnKey: string, item: TableUploadProfileData) => {
      const result: TableStyle = {};
      return result;
    };

    const addCustomStyleOnRow = (item: TableUploadProfileData, currData: TableUploadProfileData[], index: number) => {
      const result: TableStyle = {};
      return result;
    };

    const onRenderTableStarts = () => {};

    const onRenderTableEnded = () => {};

    const transformValue = (columnKey: string, item: TableUploadProfileData, initialValue: any, data: TableUploadProfileData[], index: number) => {
      return initialValue;
    };

    const _onSort = (columnKey: string, direction: TableSortDirection | undefined) => {
      let numberDirection: 0 | 1 | -1 = 0;
      if (direction === TableSortDirection.ASC) numberDirection = 1;
      else if (direction === TableSortDirection.DESC) numberDirection = -1;
      if (numberDirection !== 0) onSort?.(columnKey, numberDirection);
    };

    const renderTable = () => (
      <Table
        className={styles.table}
        data={data}
        keyExtractor={(_, item) => `key-${item.id}`}
        configuration={generateConfiguration()}
        onRenderCellRow={customRenderCell}
        onStyleCellRow={addCustomStyleOnCell}
        onStyleRow={addCustomStyleOnRow}
        onChangeValue={valueChanged}
        onRenderStarts={() => onRenderTableStarts}
        onRenderEnded={onRenderTableEnded}
        transformValue={transformValue}
        onSort={_onSort}
      />
    );

    const classes = [styles.container];
    if (className) classes.push(className);
    return (
      <div className={classes.join(" ")} style={style} ref={containerRef}>
        {renderTable()}
      </div>
    );
  }
);

TableUploadProfile.defaultProps = {
  className: undefined,
  style: undefined,
};

export default TableUploadProfile;
