import React from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from '../../components/page/PageContainer';
import { VHOST } from '../../constants/ovenmedia';
import { OvenMediaAppContext, OvenMediaStreamContext } from '../../interfaces/context';
import { OvenMediaAppStats } from '../../interfaces/stats';
import { useOvenMediaContext, useOvenMediaStats } from '../../store/context/hooks';
import TableApplication, { TableApplicationData } from './container/table-application';

import styles from './application.module.css';
import ApplicationNetworkContainer from './container/network';

const ApplicationPage = () => {
  const { app } = useParams();
  const stats = useOvenMediaStats();
  const context = useOvenMediaContext();
  const data: TableApplicationData[] = [];

  let appStats: OvenMediaAppStats | undefined;

  if (app) {
    const appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;
    appStats = app ? stats?.vhosts[VHOST]?.apps?.[app] : undefined;
    if (appContext) {
      const streams = Object.keys(appContext?.streams);
      streams.forEach((stream) => {
        const streamContext: OvenMediaStreamContext = appContext.streams?.[stream];
        if (streamContext) {
          data.push({
            context: streamContext,
            stats: appStats?.streams[stream]
          })
        }
      });
    }
  } else if (context?.vhosts[VHOST]?.apps) {
    const apps = Object.keys(context?.vhosts[VHOST]?.apps);
    apps.forEach((app) => {
      const appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;
      const currAppStats = app ? stats?.vhosts[VHOST]?.apps?.[app] : undefined;
      if (appContext) {
        const streams = Object.keys(appContext?.streams);
        streams.forEach((stream) => {
          const streamContext: OvenMediaStreamContext = appContext.streams?.[stream];
          if (streamContext) {
            data.push({
              context: streamContext,
              stats: currAppStats?.streams[stream]
            })
          }
        });
      }
    });
  }

  return (
    <PageContainer
      title={'Application' /* TRANSLATION */}
      subtitle={app}
      loading={!context}
    >
      <div style={{ width: '100%' }}>
        {
          data?.length ? (
            <>
              <div className={styles.sectionTitle}>{'ACTIVE LIVE STREAMS' /* TRANSLATION */}</div>
              <TableApplication
                showApp={app === undefined}
                data={data}
              />
            </>
          ) : (
            <div className={styles.noStream}>
              {'Aucun stream en cours ...' /* TRANSLATION */}
            </div>
          )
        }
        <br />
        {
          app ? (
            <ApplicationNetworkContainer
              stats={appStats?.network}
            />
          ) : null
        }

      </div>

    </PageContainer>
  );
};

export default ApplicationPage;
