import { closePopup, OverlayHoverMessage, PopupButtonType, showPopup, Touchable } from "@kalyzee/kast-app-web-components";
import React, { createRef, useImperativeHandle, useRef } from "react";
import { ReactComponent as IconData } from "../../../assets/icons/data.svg";
import TableRecord from "../../../components/tables/table-record";
import TableRedirection from "../../../components/tables/table-redirection";
import Table, { TableColumnType, TableConf, TableConfColumn, TableContentRef, TableSortDirection, TableStyle } from "../../../components/utils/Table";
import { toastError, toastSuccess } from "../../../helpers/toast";
import { getDateDifferenceText } from "../../../helpers/utils";
import { useSocketAppDispatch } from "../../../hooks/app";
import { useRender } from "../../../hooks/component";
import { useElementSize } from "../../../hooks/window";
import { OvenMediaLiveSession, OvenMediaRecordSession, OvenMediaRedirectionSession } from "../../../interfaces/context";
import { socketDeleteRedirection, socketStopRecord } from "../../../store/socket/actions";
import styles from "./table-session.module.css";

export interface TableSessionData extends OvenMediaLiveSession {}

export interface TableSessionRef {
  render: () => void;
}

export interface TableSessionProps {
  showApp: boolean;
  data: TableSessionData[];
  sort: { field: string; direction: -1 | 1 };
  onSort?: (field: string, direction: -1 | 1) => void;
  onItemChecked?: (item: TableSessionData) => void;
  className?: string;
  style?: React.CSSProperties;
}
const TableSession = React.forwardRef(
  ({ showApp, sort, onSort, data, onItemChecked, className, style }: TableSessionProps, forwardRef: React.ForwardedRef<TableSessionRef | undefined>) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const render = useRender();
    const size = useElementSize(containerRef);
    const socketDispatch = useSocketAppDispatch();

    useImperativeHandle(forwardRef, () => ({
      render,
    }));

    const generateConfiguration = () => {
      const columnConfiguration: TableConfColumn<TableSessionData>[] = [
        /* {
        type: TableColumnType.CHECKBOX,
        key: 'checked',
      }, */
        {
          type: TableColumnType.CLASSIC,
          key: "name",
          minWidth: "10rem",
          title: "Name" /* TRANSLATION */,
          header: { className: styles.tableHeaderCellName },
          item: { className: styles.tableCellName },
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "key",
          width: "15rem",
          title: "Stream (key)" /* TRANSLATION */,
          enableSort: true,
        },
        showApp
          ? {
              type: TableColumnType.CLASSIC,
              key: "app",
              width: "10rem",
              title: "App" /* TRANSLATION */,
              ascendantSort: (a: TableSessionData, b: TableSessionData) => a.app.localeCompare(b.app),
              descendantSort: (a: TableSessionData, b: TableSessionData) => b.app.localeCompare(a.app),
              enableSort: true,
            }
          : undefined,
        {
          type: TableColumnType.CLASSIC,
          key: "record",
          title: "Record" /* TRANSLATION */,
          width: "5rem",
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "redirections",
          title: "Redirections" /* TRANSLATION */,
          width: "6rem",
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "client",
          title: "Client" /* TRANSLATION */,
          width: "7.5rem",
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "source",
          title: "Source" /* TRANSLATION */,
          width: "7.5rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "duration",
          title: "Duration" /* TRANSLATION */,
          width: "7rem",
          enableSort: false,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "startedAt",
          title: "Start" /* TRANSLATION */,
          width: "14rem",
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "endedAt",
          title: "End" /* TRANSLATION */,
          width: "14rem",
          enableSort: true,
        },
        {
          type: TableColumnType.CLASSIC,
          key: "data",
          title: "Data" /* TRANSLATION */,
          width: "3rem",
          enableSort: false,
        },
      ].filter((curr) => curr) as TableConfColumn<TableSessionData>[];

      columnConfiguration.forEach((c) => {
        if (c.key === sort.field) {
          c.defaultSort = sort.direction === 1 ? TableSortDirection.ASC : TableSortDirection.DESC;
        }
      });

      const tableConfiguration: TableConf<TableSessionData> = {
        columns: columnConfiguration,
        header: {
          className: styles.tableHeader,
          cell: {
            className: styles.tableHeaderCell,
          },
        },
        row: {
          className: styles.tableRow,
          cell: {
            className: styles.tableRowCell,
          },
        },
        content: { className: styles.tableContent },
        valueToShowIfUndefined: { value: "-", className: styles.tableUndefinedValue },
      };

      return tableConfiguration;
    };

    // Called when a value is changed. Checkboxes here
    const valueChanged = (value: any, columnKey: string, item: TableSessionData) => {
      if (columnKey === "checked") onItemChecked?.(item);
    };

    const customRenderCell = (element: JSX.Element | null, elementRef: TableContentRef, columnKey: string, item: TableSessionData) => {
      if (columnKey === "name") {
        const ref = createRef<HTMLDivElement>();
        const name = item.name ?? "";
        return (
          <div ref={ref} className={styles.name}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Name:" /* TRANSLATION */}</div>
                      <div>{name}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {name}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={name} />
          </div>
        );
      }
      if (columnKey === "key") {
        const ref = createRef<HTMLDivElement>();
        const key = item.stream;
        return (
          <div ref={ref} className={styles.key}>
            <Touchable
              className={styles.textOverflow}
              onPress={() => {
                showPopup({
                  content: (
                    <>
                      <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Key:" /* TRANSLATION */}</div>
                      <div>{key}</div>
                    </>
                  ),
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            >
              {key}
            </Touchable>
            <OverlayHoverMessage targetRef={ref} icon={<div />} message={key} />
          </div>
        );
      }
      if (columnKey === "app") {
        return (
          <Touchable
            className={[styles.app, styles.textOverflow].join(" ")}
            onPress={() => {
              showPopup({
                content: (
                  <>
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"App:" /* TRANSLATION */}</div>
                    <div>{item.app}</div>
                  </>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {item.app}
          </Touchable>
        );
      }
      if (columnKey === "record") {
        const records: OvenMediaRecordSession[] = item.records ?? [];
        return (
          <Touchable
            className={records.length ? styles.recordYes : styles.recordFalse}
            onPress={() => {
              const popupIndex = showPopup({
                style: { width: "90%" },
                content: (
                  <div className={styles.popupRecordContainer}>
                    <div className={styles.popupTitle}>{"Records:" /* TRANSLATION */}</div>
                    <div>
                      {records.length ? (
                        <TableRecord
                          data={records.map((record, index) => ({
                            context: item,
                            session: record,
                          }))}
                          onStopRecord={async (item) => {
                            const id = item.session.id;
                            if (!id) return;
                            const result = await socketDispatch(socketStopRecord({ recordSession: id }));
                            if (!result.error) {
                              toastSuccess("La session a bien été stoppée.");
                            } else {
                              toastError(`Impossible d'arrêter la session ${id}`);
                            }
                            closePopup(popupIndex);
                          }}
                        />
                      ) : (
                        <div>Aucun enregistrement n'a eu lieu... </div>
                      )}
                    </div>
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {records.length ?? 0}
          </Touchable>
        );
      }
      if (columnKey === "redirections") {
        const redirections: OvenMediaRedirectionSession[] = item.redirections ?? [];
        return (
          <Touchable
            className={redirections.length ? styles.redirectionYes : styles.redirectionFalse}
            onPress={() => {
              const popupIndex = showPopup({
                style: { width: "90%" },
                content: (
                  <div>
                    <div className={styles.popupTitle}>{"Redirections:" /* TRANSLATION */}</div>
                    <div>
                      {redirections.length ? (
                        <TableRedirection
                          data={redirections.map((record, index) => ({
                            context: item,
                            session: record,
                          }))}
                          onStopRedirection={async (item) => {
                            const id = item.session.id;
                            if (!id) return;
                            const result = await socketDispatch(socketDeleteRedirection({ redirectionSession: id }));
                            if (!result.error) {
                              toastSuccess("La session a bien été stoppée.");
                            } else {
                              toastError(`Impossible d'arrêter la session ${id}`);
                            }
                            closePopup(popupIndex);
                          }}
                        />
                      ) : (
                        <div>Aucune redirection n'a eu lieu... </div>
                      )}
                    </div>
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {redirections.length ?? 0}
          </Touchable>
        );
      }
      if (columnKey === "client") {
        return (
          <Touchable
            className={[styles.client, styles.textOverflow].join(" ")}
            onPress={() => {
              showPopup({
                content: (
                  <>
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Client:" /* TRANSLATION */}</div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, maxWidth: "100%" }}>
                      <div>{"• IP: " /* TRANSLATION */}</div>
                      <div className={[styles.textOverflow].join(" ")}>{`${item.clientIp}`}</div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, maxWidth: "100%" }}>
                      <div>{"• PORT: " /* TRANSLATION */}</div>
                      <div className={[styles.textOverflow].join(" ")}>{`${item.clientPort}`}</div>
                    </div>
                  </>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {item.clientIp}
          </Touchable>
        );
      }
      if (columnKey === "source") {
        const originSource = item.url;
        const newSource = item.newUrl;
        return (
          <Touchable
            className={[styles.source, styles.textOverflow].join(" ")}
            onPress={() => {
              showPopup({
                content: (
                  <>
                    <div style={{ marginBottom: 10, fontWeight: "bold" }}>{"Source:" /* TRANSLATION */}</div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, maxWidth: "100%" }}>
                      <div>{"• origin: " /* TRANSLATION */}</div>
                      <a className={[styles.recordLink].join(" ")} href={originSource} target="_blank">{`${originSource}`}</a>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, maxWidth: "100%" }}>
                      <div>{"• final: " /* TRANSLATION */}</div>
                      <a className={[styles.recordLink].join(" ")} href={newSource} target="_blank">{`${newSource}`}</a>
                    </div>
                  </>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            {item.protocol?.toUpperCase()}
          </Touchable>
        );
      }
      if (columnKey === "data") {
        if (!item.data) return '-';
        return (
          <Touchable
            className={styles.actionPlay}
            onPress={() => {
              let data = item.data;
              if (data) {
                try {
                  const json = JSON.parse(data);
                  data = JSON.stringify(json, null, 4);
                } catch (err) {}
              }

              showPopup({
                content: (
                  <div>
                    <div className={styles.popupTitle}>Data:</div>
                    {data}
                  </div>
                ),
                buttons: [{ type: PopupButtonType.OK, element: "OK" }],
              });
            }}
          >
            <IconData width={20} height={20} />
          </Touchable>
        );
      } 
      return element;
    };

    const addCustomStyleOnCell = (columnKey: string, item: TableSessionData) => {
      const result: TableStyle = {};
      return result;
    };

    const addCustomStyleOnRow = (item: TableSessionData, currData: TableSessionData[], index: number) => {
      const result: TableStyle = {};
      return result;
    };

    const transformValue = (columnKey: string, item: TableSessionData, initialValue: any, data: TableSessionData[], index: number) => {
      if (columnKey == "startedAt") {
        if (!item.startedAt) return undefined;
        const startedAt = new Date(item.startedAt);
        return `${startedAt.toLocaleDateString()} ${startedAt.toLocaleTimeString()}`;
      }
      if (columnKey == "endedAt") {
        if (!item.endedAt) return undefined;
        const endedAt = new Date(item.endedAt);
        return `${endedAt.toLocaleDateString()} ${endedAt.toLocaleTimeString()}`;
      }
      if (columnKey === "duration") {
        if (!item.startedAt || !item.endedAt) return undefined;
        const startedAt = new Date(item.startedAt);
        const endedAt = new Date(item.endedAt);
        return getDateDifferenceText(startedAt, endedAt);
      }
      return initialValue;
    };

    const _onSort = (columnKey: string, direction: TableSortDirection | undefined) => {
      let numberDirection: 0 | 1 | -1 = 0;
      if (direction === TableSortDirection.ASC) numberDirection = 1;
      else if (direction === TableSortDirection.DESC) numberDirection = -1;
      if (numberDirection !== 0) {
        if (columnKey === "record") onSort?.("nbOfRecords", numberDirection);
        else if (columnKey === "redirections") onSort?.("nbOfRedirections", numberDirection);
        else onSort?.(columnKey, numberDirection);
      }
    };

    const onRenderTableStarts = () => {};

    const onRenderTableEnded = () => {};

    const renderTable = () => (
      <Table
        className={styles.table}
        data={data}
        keyExtractor={(_, item) => `key-${item.id}`}
        configuration={generateConfiguration()}
        onRenderCellRow={customRenderCell}
        onStyleCellRow={addCustomStyleOnCell}
        onStyleRow={addCustomStyleOnRow}
        transformValue={transformValue}
        onChangeValue={valueChanged}
        onRenderStarts={() => onRenderTableStarts}
        onRenderEnded={onRenderTableEnded}
        onSort={_onSort}
      />
    );

    const classes = [styles.container];
    if (className) classes.push(className);
    return (
      <div className={classes.join(" ")} style={style} ref={containerRef}>
        {renderTable()}
      </div>
    );
  }
);

TableSession.defaultProps = {
  className: undefined,
  style: undefined,
};

export default TableSession;
