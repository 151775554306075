import { MiddlewareAPI } from "@reduxjs/toolkit";
import { HttpStatusCode } from "../constants/httpStatusCode";
import { logout } from "../store/session/slices";
import { ApiError } from "./apiError";
import { getEnvApiUrl, getEnvOvenMediaWebsocketUrl } from "./env";
import storage from "./storage";
import { addToPathNameUrl } from "./utils";

const getUrl = () => new URL(window.location.href);

const getQueryUrl = (query: string, protocol?: string) => {
  const url = getUrl();
  let queryUrlStr = url.searchParams.get(query);
  if (!queryUrlStr) return undefined;
  if (queryUrlStr.startsWith(':')) {
    queryUrlStr = url.hostname + queryUrlStr;
  }
  if (!queryUrlStr.startsWith('http')) {
    queryUrlStr =  url.protocol + '//' + queryUrlStr;
  }
  const queryUrl = new URL(queryUrlStr);
  if (protocol) queryUrl.protocol = protocol;
  return queryUrl.href;
};

const apiUrlFromQuery = getQueryUrl('apiUrl');
const getQueryApiUrl = (protocol : string) => {
  if (!apiUrlFromQuery) return undefined;
  const url = new URL(apiUrlFromQuery);
  if (protocol) url.protocol = protocol;
  return url.href;
};

const ovenMediaSocketUrlFromQuery = getQueryUrl('ovenmediaSocketUrl');
const getQueryOvenMediaSocketUrl = () => {
  if (!ovenMediaSocketUrlFromQuery) return undefined;
  const url = new URL(ovenMediaSocketUrlFromQuery);
  url.protocol = url.protocol.replace('http', 'ws');
  return url.href;
};

if (!(window as any).__custom_local_data__) (window as any).__custom_local_data__ = {};
(window as any).__custom_local_data__.query = {
  apiUrl: apiUrlFromQuery,
  ovenmediaSocketUrl: ovenMediaSocketUrlFromQuery,
}

export const getConfQueries = () => {
  const queries: {[key: string] : string} = (window as any).__custom_local_data__?.query ?? {};
  return queries;
}
// Source: react-boilerplate => https://github.com/react-boilerplate/react-boilerplate  (app/utils/request.js)

/**
 * Build backend url
 *
 * @returns {string} Returns a url as string
 */
const buildBaseUrl = (protocol : string) => {
  const url = getUrl();
  const hostNameSplitted = url.hostname.split('.');
  hostNameSplitted.splice(0, 1);
  const hostNameStr = hostNameSplitted.join('.');
  // return 'https://dro-1.dev.kalyzee.com/';
  return 'http://localhost:8088/';
  return `${protocol}//api.${hostNameStr}/`;
};

/**
 * Get the current backend base (root) url
 *
 * @returns {string} Returns a url as string
 */
export const getBaseUrl = () => {
  const url = getUrl();
  const urlFromQuery = getQueryApiUrl(url.protocol);
  if (urlFromQuery) return urlFromQuery;
  const envApiUrl = getEnvApiUrl();
  if(envApiUrl) return envApiUrl;
  return buildBaseUrl(url.protocol);
};

export const getVideoUrl = () => {
  return addToPathNameUrl(getBaseUrl(), 'videos');
};

export const getOvenMediaWebsocketUrl = () => {
  const urlFromQuery = getQueryOvenMediaSocketUrl();
  if (urlFromQuery) return urlFromQuery;
  const envUrl = getEnvOvenMediaWebsocketUrl();
  if(envUrl) return envUrl;
  return 'ws://localhost:3333';
}

export const getOvenMediaHlsUrl = () => {
  const envUrl = getOvenMediaWebsocketUrl();
  return envUrl.replace('ws', 'http');
}

const getSocketBaseUrl = () => {
  const url = getUrl();
  const urlFromQuery = getQueryApiUrl(url.protocol.replace('http', 'ws'));
  if (urlFromQuery) return urlFromQuery;
  const envApiUrl = getEnvApiUrl();
  if(envApiUrl) {
    const urlObj = new URL(envApiUrl);
    // Keep same security level : http -> ws & https -> wss
    urlObj.protocol = urlObj.protocol.replace('http', 'ws');
    return urlObj.href;
  }
  // Keep same security level : http -> ws & https -> wss
  return `${buildBaseUrl(url.protocol.replace('http', 'ws'))}`;
};

export const getUserSocketUrl = () => addToPathNameUrl(getSocketBaseUrl(), '/admin');

export const getAuthHeaders = (token: string) => ({ Authorization: `Bearer ${token}` });

// TODO : token retrieval
export const getHeaders = (authenticated: boolean) => {
  let headers = {
    'Content-Type': 'application/json',
  };
  if (authenticated) {
    const token = storage.getToken();
    if (token) {
      const authHeaders = getAuthHeaders(token);
      headers = { ...headers, ...authHeaders };
    }
  }
  return headers;
};

export const getInit = (
  method: string,
  body?: object,
  authenticated: boolean = true,
) : RequestInit => {
  const init = {
    method,
    body: JSON.stringify(body as BodyInit),
    headers: getHeaders(authenticated),
  };
  return init;
};

export const request = async (
  store: MiddlewareAPI,
  url: string,
  method: string,
  body?: object,
  authenticated: boolean = true,
) : Promise<any> => {
  const fullUrl = addToPathNameUrl(getBaseUrl(), url);
  const response = await fetch(fullUrl, getInit(method, body, authenticated));
  const data = await response.json();
  if (response.ok) {
    return data;
  }
  if (response.status === HttpStatusCode.Forbidden || response.status === HttpStatusCode.TokenIsExpired) {
    store.dispatch(logout());
  }
  const error: ApiError = new ApiError(data);
  // The api error is described by the JSON body :
  throw error;
};

