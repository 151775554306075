import React from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from '../../components/page/PageContainer';
import { VHOST } from '../../constants/ovenmedia';
import { OvenMediaAppContext, OvenMediaAppOutputProfile, OvenMediaAppOutputProfileEncodeAudio, OvenMediaAppOutputProfileEncodeVideo, OvenMediaAppOutputProfilePlaylist } from '../../interfaces/context';
import { useOvenMediaContext, useOvenMediaStats } from '../../store/context/hooks';
import TableAudioEncoder from './containers/table-audio-encoder';
import TablePlaylistRendition from './containers/table-renditions-encoder';
import TableVideoEncoder from './containers/table-video-encoder';
import styles from './playlists.module.css';


const PlaylistsPage = () => {
  const { app } = useParams();
  const stats = useOvenMediaStats();
  const context = useOvenMediaContext();

  const appContext: OvenMediaAppContext | undefined = app ? context?.vhosts[VHOST]?.apps?.[app] : undefined;

  const profiles: OvenMediaAppOutputProfile[] = appContext?.outputProfiles ?? [];
  const playlists: OvenMediaAppOutputProfilePlaylist[] = [];
  const videoEncoders: OvenMediaAppOutputProfileEncodeVideo[] = [];
  const audioEncoders: OvenMediaAppOutputProfileEncodeAudio[] = [];

  if (profiles) {
    profiles.forEach((profile) => {
      if (profile.encodes?.videos) {
        videoEncoders.push(...profile.encodes.videos);
      }
      if (profile.encodes?.audios) {
        audioEncoders.push(...profile.encodes.audios);
      }
      if (profile.playlists) {
        playlists.push(...profile.playlists);
      }
    });
  }

  const renderPlaylist = (playlist: OvenMediaAppOutputProfilePlaylist) => {
    if (!playlist) return null;
    return (
      <div key={`key_playlist_${playlist.name}`} className={styles.playlistContainer}>
        <div className={styles.playlistTitle}>{playlist.name}</div>
        <div className={styles.playlistSeparator}></div>
        <div className={styles.playlistSubtitle}>{'Renditions' /* TRANSLATION */}</div>
        <TablePlaylistRendition
          data={playlist.renditions}
        />
      </div>
    )
  }

  return (
    <PageContainer
      title={'Playlists' /* TRANSLATION */}
      subtitle={app}
      loading={!appContext}
    >
      <div style={{ width: '100%' }}>
        <div className={styles.title}>{'Playlists' /* TRANSLATION */}</div>
        <br />
        <>
          {
            playlists.map((playlist) => renderPlaylist(playlist))
          }
        </>

        <br />

        <div className={styles.title}>{'Video encoders' /* TRANSLATION */}</div>
        <TableVideoEncoder
          data={videoEncoders}
        />

        <br />

        <div className={styles.title}>{'Audio encoders' /* TRANSLATION */}</div>
        <TableAudioEncoder
          data={audioEncoders}
        />

        <br />
      </div>
    </PageContainer>
  );
};

export default PlaylistsPage;
